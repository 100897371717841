import React from 'react'
import styles from './SideRunner.module.scss'
import { graphql, useStaticQuery} from 'gatsby'
import PropTypes from 'prop-types';
import SideInfo from '../sideinfo/SideInfo';

const getContact = graphql`
  query {
    allContentfulKontakt {
      edges {
        node {
          nazevFirmy
          adresaProvozovatele
          ico
          dic
        }
      }
    }
  }
`

const SideRunner = ({hide}) => {  
  const { allContentfulKontakt : {edges} } = useStaticQuery(getContact);
  const { nazevFirmy, adresaProvozovatele, ico, dic } = edges[0].node;

   return (
    <div className={`${styles.sideRunner} ${hide ? "hide" : styles.show}`}>
     <h3 className={`sideHeading ${hide ? '' : "no-line"}`}>Provozovatel</h3>
     <div className={styles.infoWrapper}>
        <SideInfo heading="Název firmy:" info={nazevFirmy} />
        <SideInfo heading="Adresa" info={adresaProvozovatele} />
        <SideInfo heading="IČO" info={ico} />
        <SideInfo heading="DIČ" info={dic} />
      </div>
    </div>
  )
}

export default SideRunner

SideRunner.propTypes = {
  hide: PropTypes.bool,
}

import React from "react"
import Layout from "../components/layout/Layout"
import Main from "../components/pageLayout/Main"
import Sidebar from "../components/pageLayout/Sidebar"
import Content from "../components/pageLayout/Content"
import Banner from "../components/header/Banner"
import SideShop from "../components/shop/SideShop"
import SideContact from "../components/contact/SideContact"
import Form from "../components/contact/Form"
import Map from "../components/map/Map"
import SideRunner from "../components/contact/SideRunner"
import SEO from "../components/SEO"
import { graphql, useStaticQuery } from "gatsby"

export default () => {
  const image = useStaticQuery(graphql`
    query {
      contact: contentfulAsset(title: { eq: "Prodejna" }) {
        fluid(maxWidth: 1600, quality: 50) {
          ...GatsbyContentfulFluid
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Kontakt" />
      <Banner
        text="Labore aliqua proident sunt cupidatat voluptate elit sit ex. Anim excepteur veniam nisi cillum anim sint nisi ullamco. Cupidatat eiusmod eu dolor ad excepteur pariatur ea culpa do aliqua voluptate."
        heading="Kontakt"
        img={image.contact.fluid}
      />
      <Main>
        <Sidebar>
          <SideContact />
          <SideShop />
          <SideRunner hide={true} />
        </Sidebar>
        <Content>
          <h3 className="no-line">Kontaktní formulář</h3>
          <Form />
          <h3 className="no-line">Kde nás najdete</h3>
          <Map />
          <SideRunner />
        </Content>
      </Main>
    </Layout>
  )
}
